import React from "react";
import { useLocation } from "react-router-dom";
import pathModule from "./../../../../../utils/path/pathModule.json";

const StatusList = ({ fromComponent }) => {
  let location = useLocation();
  let arrPath = location.pathname.split("/");
  let API_Path = `/${arrPath[1]}/${arrPath[2]}`;

  const contractCustomerPath = pathModule.free_delivery.contract_customer.path;
  const returnWarehousePath = pathModule.free_delivery.return_warehouse.path;
  const outOfDeliveryPath = pathModule.free_delivery.out_of_delivery.path;

  const contractCustomerSearchStatus =
    pathModule.free_delivery.contract_customer.searchStatus;
  const returnWarehouseSearchStatus =
    pathModule.free_delivery.return_warehouse.searchStatus;
  const outOfDeliverySearchStatus =
    pathModule.free_delivery.out_of_delivery.searchStatus;

  const contractCustomerFormStatus =
    pathModule.free_delivery.contract_customer.formStatus;
  const returnWarehouseFormStatus =
    pathModule.free_delivery.return_warehouse.formStatus;
  const outOfDeliveryFormStatus =
    pathModule.free_delivery.out_of_delivery.formStatus;

  const getDropdownMenu = (API_Path, fromComponent) => {
    switch (API_Path) {
      case contractCustomerPath:
        let contractCustomer;

        fromComponent === "search"
          ? (contractCustomer = contractCustomerSearchStatus.map((status) => {
              return (
                <option kay={status.id} value={status.value}>
                  {status.name}
                </option>
              );
            }))
          : (contractCustomer = contractCustomerFormStatus.map((status) => {
              return (
                <option kay={status.id} value={status.value}>
                  {status.name}
                </option>
              );
            }));
        return contractCustomer;

      case returnWarehousePath:
        let returnWarehouse;

        fromComponent === "search"
          ? (returnWarehouse = returnWarehouseSearchStatus.map((status) => {
              return (
                <option kay={status.id} value={status.value}>
                  {status.name}
                </option>
              );
            }))
          : (returnWarehouse = returnWarehouseFormStatus.map((status) => {
              return (
                <option kay={status.id} value={status.value}>
                  {status.name}
                </option>
              );
            }));
        return returnWarehouse;

      case outOfDeliveryPath:
        let outOfDelivery;
        fromComponent === "search"
          ? (outOfDelivery = outOfDeliverySearchStatus.map((status) => {
              return (
                <option kay={status.id} value={status.value}>
                  {status.name}
                </option>
              );
            }))
          : (outOfDelivery = outOfDeliveryFormStatus.map((status) => {
              return (
                <option kay={status.id} value={status.value}>
                  {status.name}
                </option>
              );
            }));
        return outOfDelivery;

      default:
        return <option value=""></option>;
    }
  };

  return getDropdownMenu(API_Path, fromComponent);
};

export default StatusList;
